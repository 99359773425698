import { Id } from "./common";
import { Pdb } from "./Pdb";
import { DrugInteraction, DrugInteractionMethods } from "./DrugInteraction";
import JSZip from "jszip";
import fetch from "node-fetch";

export type ScorePocketType = "bindingSiteScore";

export interface Pocket {
    id: Id;
    name: string;
    description: string;
    bindingSiteScore: number;
    pdb: Pdb;
}

export class PocketMethods {
    constructor(public pocket: Pocket) {}

    async getDownload(
        drugInteractions: DrugInteraction[]
    ): Promise<{ filename: string; blob: Blob }> {
        var zip = new JSZip();

        // Generate CSV
        const header = Object.keys(drugInteractions[0]).join(",");
        const values = drugInteractions
            .map(drugInteraction => new DrugInteractionMethods(drugInteraction).toCSV)
            .join("\n");
        const csv = header + "\n" + values;
        zip.file(`${this.pocket.name}.csv`, csv);

        // Generate PDBs
        var pdbs = zip.folder("pdbs");
        const res = await drugInteractions.map(async drugInteraction => {
            await fetch(new DrugInteractionMethods(drugInteraction).downloadUrl)
                .then(response => response.arrayBuffer())
                .then(data => {
                    if (pdbs) {
                        return pdbs.file(drugInteraction.pdbIdPose, data);
                    }
                });
        });

        // Generate ZIP file
        return Promise.all(res).then(async () => {
            const content = await zip.generateAsync({ type: "blob" });
            return {
                filename: `${this.pocket.name}.zip`,
                blob: content,
            };
        });
    }
}
