import { Pocket } from "./Pocket";

export type ScoreType = "ligandEfficiency" | "dockingScore";

export interface DrugInteraction {
    // id: number;
    pdbFileURL: string;
    pdbIdPose: string;
    poseId: number;
    smilesCode: string;
    xref: string;
    compoundId: string;
    dockingScore: number;
    ligandEfficiency: number;
    pocket: Pocket;
}

export class DrugInteractionMethods {
    constructor(public drugInteraction: DrugInteraction) {}

    get imageUrl(): string {
        return this.drugInteraction.xref + ".png";
    }

    get pubChemQueryUrl(): string {
        const query = this.drugInteraction.compoundId.replace(/^ZINC/, "");
        return `https://pubchem.ncbi.nlm.nih.gov/#query=${query}`;
    }

    get downloadUrl(): string {
        const path = this.drugInteraction.pdbFileURL;
        return `http://campins.cnb.csic.es:8600/${path}`;
    }

    get toCSV(): string {
        const csv = [];
        for (const [key, value] of Object.entries(this.drugInteraction)) {
            if (key === 'pdbFileURL'){
                csv.push(this.downloadUrl)
            }
            else if (key === 'pocket'){
                csv.push(this.drugInteraction.pocket.name);
            }
            else{
                csv.push(value);
            }
        }
        return csv.join(",");
    }
}
