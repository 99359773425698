import { ExperimentRepository } from "./../repositories/ExperimentRepository";
import { Experiment } from "../entities/Experiment";
import { Id } from "../entities/common";

export class GetExperiments {
    constructor(private experimentRepository: ExperimentRepository) {}

    public async execute(id: Id): Promise<Experiment> {
        return this.experimentRepository.getExperiment(id);
    }
}
