import React from "react";
import { Experiment } from "../../domain/entities/Experiment";
import { Protein } from "../../domain/entities/Protein";
import { Pocket } from "../../domain/entities/Pocket";
import { Pdb } from "../../domain/entities/Pdb";
import { FilterSelect } from "./FilterSelect";
import { useCompositionRoot } from "../../CompositionRootContext";
import { t } from "../../i18n";

interface FilterProps {
    experiment: Experiment;
    onPocketChange(pocket: Pocket | undefined): void;
}

type State = Partial<{
    protein: Protein;
    pdb: Pdb;
    pdbs: Pdb[] | null;
    pocket: Pocket;
}>;

export const Filter: React.FC<FilterProps> = React.memo(props => {
    const compositionRoot = useCompositionRoot();
    const { experiment, onPocketChange } = props;
    const [state, setState] = React.useState<State>(() => getInitialState(experiment));
    const [pdbs, setPdbs] = React.useState<Pdb[]>();
    const [pockets, setPockets] = React.useState<Pocket[]>();
    const { protein, pdb, pocket } = state;
    React.useEffect(() => onPocketChange(pocket), [onPocketChange, pocket]);
    React.useEffect(() => {
        if (protein){
            compositionRoot.experiments.getPdbs(protein).then(setPdbs);
        }
    }, [compositionRoot, protein]);
    React.useEffect(() => {
        if (pdb){
            compositionRoot.experiments.getPockets(pdb).then(setPockets);
        }
    }, [compositionRoot, pdb]);

    return (
        <div id="filteringBox" className="defaultBox proteinBox">
            <div id="filtering" className="contentData">
                <p className="d-inline-block">
                    <em>{t("Select")}:</em>
                    <br />
                </p>

                <FilterSelect<Protein>
                    title={t("Protein")}
                    selected={protein}
                    onChange={protein => setState({ protein })}
                    options={experiment.targetProteins}
                />

                <FilterSelect<Pdb>
                    title={t("PDB Target")}
                    selected={pdb}
                    onChange={pdb => setState({ protein, pdb })}
                    options={pdbs}
                />

                <FilterSelect<Pocket>
                    title={t("Pocket")}
                    selected={pocket}
                    onChange={pocket => setState({ protein, pdb, pocket })}
                    options={pockets}
                />
            </div>
        </div>
    );
});

function getInitialState(experiment: Experiment) {
    // return !isDevDebug()
    //     ? {}
    //     : {
    //           protein: experiment.targetProteins[0],
    //           pdb: experiment.targetProteins[0].targetPDBs[0],
    //           pocket: experiment.targetProteins[0].targetPDBs[0].pockets[0],
    //       };
    return {}
}

// function isDevDebug() {
//     return localStorage.getItem("debug") === "true";
// }
