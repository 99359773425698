import { DrugInteraction } from "./../domain/entities/DrugInteraction";
import fetch from "node-fetch";

import { ExperimentRepository } from "./../domain/repositories/ExperimentRepository";
import { Experiment } from "../domain/entities/Experiment";
import { Protein } from "../domain/entities/Protein";
import { Pdb } from "../domain/entities/Pdb";
import { Pocket } from "../domain/entities/Pocket";
import { PaginatedResponse } from "../domain/repositories/ExperimentRepository";
import { Order } from "../components/drug-interactions/order";

interface ExperimentApi {
    id: number;
    name: string;
    targetProteins: string[];
}

interface ProteinApi {
    name: string;
    experiment: number;
    geneBankId: string;
    targetPDBs: string[];
}

interface PdbApi {
    pdbId: string;
}

interface PocketApi {
    experiment: number;
    targetProtein: string;
    targetPdb: string;
    pocketId: number;
    description: string;
    drugInteractions: string[]; // "1: 6LU7_ZINC000229984288_1.pdb | 1: P-1-1-6LU7 | ZINC000229984288",
    bindingSiteScore: number;
}

interface CompoundApi {
    experiment: string;
    compoundId: string;
    smilesCode: string;
    library: string;
    xref: string;
}

interface DrugInteractionApi {
    experiment: number;
    compound: CompoundApi;
    targetProtein: string;
    targetPdb: string;
    pocket: number;
    poseId: number;
    // id: number;
    description: string;
    pdbFileURL: string;
    pdbIdPose: string;
    dockingScore: number;
    ligandEfficiency: number;
}

type Endpoint = "experiments" | "targets" | "pockets" | "compounds" | "interactions";
type Filter = Partial<{ ids: number[] }>;

interface Identifiable {
    id?: number;
    name?: string;
    compoundId?: string;
}

interface PaginatedApiResponse<T> {
    count: number;
    next: number | null;
    previous: number | null;
    results: T[];
}

export interface Options {
    perPage: number;
    page: number;
    order?: Order<keyof DrugInteraction>;
    search?: string;
}

class DseApi {
    constructor(private baseUrl: string) {}

    async get<T extends Identifiable>(endpoint: Endpoint, id: number): Promise<T> {
        const url = `${this.baseUrl}/${endpoint}/${id}.json`;
        return fetch(url).then(res => res.json());
    }

    async getMany<T extends Identifiable>(endpoint: Endpoint, filter?: Filter): Promise<T[]> {
        const url = `${this.baseUrl}/${endpoint}.json`;
        const response: PaginatedResponse<T> = await fetch(url).then(res => res.json());
        const objs = response.results;
        return objs;
    }

    async getManyProteins<ProteinApi>(id: number): Promise<ProteinApi[]> {
        const url = `${this.baseUrl}/experiments/${id}/proteins`;
        const response: PaginatedResponse<ProteinApi> = await fetch(url).then(res => res.json());
        const objs = response.results;
        return objs;
    }

    async getManyPdbs<PdbApi>(experimentId: number, proteinName: string): Promise<PdbApi[]> {
        const url = `${this.baseUrl}/experiments/${experimentId}/proteins/${proteinName}/pdbs/`;
        const response: PaginatedResponse<PdbApi> = await fetch(url).then(res => res.json());
        const objs = response.results;
        return objs;
    }

    async getManyPockets<PocketApi>(
        experimentId: number,
        proteinName: string,
        pdbId: string
    ): Promise<PocketApi[]> {
        const url = `${this.baseUrl}/experiments/${experimentId}/proteins/${proteinName}/pdbs/${pdbId}/pockets`;
        const response: PaginatedResponse<PocketApi> = await fetch(url).then(res => res.json());
        const objs = response.results;
        return objs;
    }

    async getManyDrugInteractions<DrugInteractionApi>(
        experimentId: number,
        proteinName: string,
        pdbId: string,
        pocketId: number,
        options: Options
    ): Promise<PaginatedApiResponse<DrugInteractionApi>> {
        const url = `${this.baseUrl}/experiments/${experimentId}/proteins/${proteinName}/pdbs/${pdbId}/pockets/${pocketId}/interactions`;
        const params = {
            page: options.page.toString(),
            limit: options.perPage.toString(),
            search: options.search ? options.search : "",
            ordering: (options.order?.direction === "desc" ? "-" : "") + options.order?.field,
        };

        const urlParams = new URLSearchParams(params);
        return fetch(url + "?" + urlParams).then(res => res.json());
    }
}

export class ExperimentApiRepository implements ExperimentRepository {
    api: DseApi;

    constructor(baseUrl: string) {
        this.api = new DseApi(baseUrl);
    }

    async getExperiment(experimentId: number): Promise<Experiment> {
        const [experiment, targets] = await Promise.all([
            this.api.get<ExperimentApi>("experiments", experimentId),
            this.api.getManyProteins<ProteinApi>(experimentId),
        ]);

        return {
            id: experiment.id,
            name: experiment.name,
            targetProteins: targets.map(apiProtein => ({
                id: apiProtein.name,
                name: apiProtein.name,
                geneBankId: apiProtein.geneBankId,
                experimentId: experimentId,
            })),
        };
    }

    async getPdbs(protein: Protein): Promise<Pdb[]> {
        const pdbs = await this.api.getManyPdbs<PdbApi>(protein.experimentId, protein.id);
        return pdbs.map(pdb => ({
            id: pdb.pdbId,
            name: pdb.pdbId,
            protein: protein,
        }));
    }

    async getPockets(pdb: Pdb): Promise<Pocket[]> {
        const pockets = await this.api.getManyPockets<PocketApi>(
            pdb.protein.experimentId,
            pdb.protein.id,
            pdb.id
        );

        return pockets.map(pocket => ({
            id: pocket.pocketId,
            name: pocket.description,
            description: pocket.description,
            bindingSiteScore: pocket.bindingSiteScore,
            pdb: pdb,
        }));
    }

    async getDrugInteractions(
        pocket: Pocket,
        options: Options
    ): Promise<PaginatedResponse<DrugInteraction>> {
        const drugInteractions = await this.api.getManyDrugInteractions<DrugInteractionApi>(
            pocket.pdb.protein.experimentId,
            pocket.pdb.protein.id,
            pocket.pdb.id,
            pocket.id,
            options
        );
        const objs = drugInteractions.results.map(drugInteraction => ({
            pdbFileURL: drugInteraction.pdbFileURL,
            pdbIdPose: drugInteraction.pdbIdPose,
            poseId: drugInteraction.poseId,
            smilesCode: drugInteraction.compound.smilesCode,
            xref: drugInteraction.compound.xref,
            compoundId: drugInteraction.compound.compoundId,
            dockingScore: drugInteraction.dockingScore,
            ligandEfficiency: drugInteraction.ligandEfficiency,
            pocket: pocket,
        }));

        return {
            count: drugInteractions.count,
            next: drugInteractions.next,
            previous: drugInteractions.previous,
            results: objs,
        };
    }
}
