import { ExperimentRepository } from "./../repositories/ExperimentRepository";
import { Pdb } from "../entities/Pdb";
import { Protein } from "../entities/Protein";

export class GetPdbs {
    constructor(private experimentRepository: ExperimentRepository) {}

    public async execute(protein: Protein): Promise<Pdb[]> {
        return this.experimentRepository.getPdbs(protein);
    }
}
