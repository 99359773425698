import React from "react";
import "./App.css";
import { InteractionsList } from "./components/drug-interactions/InteractionsList";
import { CompositionRootProvider } from "./CompositionRootContext";

interface AppProps {
    apiUrl: string;
}

const App: React.FC<AppProps> = props => {
    const { apiUrl } = props;

    return (
        <CompositionRootProvider apiUrl={apiUrl}>
            <InteractionsList />
        </CompositionRootProvider>
    );
};

export default React.memo(App);
