import React from "react";
import { Order } from "./order";

export interface ColumnProps<Field> {
    title: string;
    help?: string;
    field: Field;
    toggleOrder?: (field: Field) => () => void;
    order?: Order<Field>;
}

export function ColumnHeader<Field>(props: ColumnProps<Field>) {
    const { title, help, field, toggleOrder: setPagination, order } = props;
    return (
        <th>
            <a style={styles.title}  onClick={setPagination ? setPagination(field) : undefined}>
                {title}
                {order && order.field === field ? (
                    order.direction === "desc" ? (
                        <i className="fa fa-chevron-down" />
                    ) : (
                        <i className="fa fa-chevron-up" />
                    )
                ) : null}
                {help && (
                    <i className="fa fa-info-circle" style={styles.information} title={help} />
                )}
            </a>
        </th>
    );
}

const styles = {
    title: { cursor: "pointer", color: "#268bbe" },
    information: { fontSize: "1.2em", color: "#8E8E8E" },
};
