import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { t } from "../../i18n";
import { DrugInteraction, DrugInteractionMethods } from "../../domain/entities/DrugInteraction";

interface InteractionRowProps {
    drugInteraction: DrugInteraction;
}

export const InteractionRow: React.FC<InteractionRowProps> = React.memo(props => {
    const { drugInteraction } = props;
    const drugInteractionMethods = new DrugInteractionMethods(drugInteraction);
    const noop = React.useCallback((ev: React.FormEvent<EventTarget>) => ev.preventDefault(), []);
    const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    const openLightbox = React.useCallback(() => setLightboxOpen(true), [setLightboxOpen]);
    const closeLightbox = React.useCallback(() => setLightboxOpen(false), [setLightboxOpen]);

    return (
        <tr>
            <td>{drugInteraction.compoundId}</td>
            <td>{drugInteraction.poseId}</td>
            <td>{drugInteraction.dockingScore?.toFixed(2) || "-"}</td>
            <td>{drugInteraction.ligandEfficiency?.toFixed(2) || "-"}</td>
            <td>
                <img
                    src={drugInteractionMethods.imageUrl}
                    alt={drugInteraction.compoundId}
                    onClick={openLightbox}
                />
                {isLightboxOpen && (
                    <Lightbox
                        mainSrc={drugInteractionMethods.imageUrl}
                        onCloseRequest={closeLightbox}
                    />
                )}
            </td>
            <td>
                <a href={drugInteractionMethods.downloadUrl} title={t("Download PDB")}>
                    <i className="fa fa-download" />
                </a>

                <CopyToClipboard text={drugInteraction.smilesCode}>
                    <a href="#" onClick={noop} title={t("Copy Smile to clipboard")}>
                        <i className="fa fa-clipboard" />
                    </a>
                </CopyToClipboard>

                <br />

                <a
                    href={drugInteraction.xref}
                    title={t("Open external link to Zinc")}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fa fa-external-link-square" />
                </a>

                <a
                    href={drugInteractionMethods.pubChemQueryUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("Search in Pubchem")}
                >
                    <i className="fa fa-search" />
                </a>
            </td>
        </tr>
    );
});
