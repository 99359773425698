interface Options {
    filename: string;
    blob: Blob;
}

export function downloadFile(options: Options): void {
    const { filename, blob } = options;
    const element =
        document.querySelector<HTMLAnchorElement>("#download") || document.createElement("a");
    element.id = "download-file";
    element.href = window.URL.createObjectURL(blob);
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
}
