import React from "react";
import { Filter } from "./Filter";
import { DrugInteractions } from "./DrugInteractions";
import { PocketInformation } from "./PocketInformation";
import { Experiment } from "../../domain/entities/Experiment";
import { useCompositionRoot } from "../../CompositionRootContext";
import { Pocket } from "../../domain/entities/Pocket";

export const InteractionsList: React.FC = React.memo(() => {
    const compositionRoot = useCompositionRoot();
    const [experiment, setExperiment] = React.useState<Experiment | undefined>();
    const [pocket, setPocket] = React.useState<Pocket | undefined>();
    React.useEffect(() => {
        compositionRoot.experiments.getExperiments(1).then(setExperiment);
    }, [compositionRoot]);

    if (!experiment) return null;

    return (
        <React.Fragment>
            <Filter experiment={experiment} onPocketChange={setPocket} />
            <PocketInformation pocket={pocket} />
            <DrugInteractions pocket={pocket} />
        </React.Fragment>
    );
});
