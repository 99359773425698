import React from "react";

interface FilterSelectProps<Option extends Obj> {
    title: string;
    selected: Option | undefined;
    options: Option[] | undefined;
    onChange: (option: Option | undefined) => void;
}

interface Obj {
    id: { toString(): string };
    name: string;
}

export function FilterSelect<Option extends Obj>(props: FilterSelectProps<Option>) {
    const { title, selected, options, onChange } = props;

    const setSelectedOption = React.useCallback(
        (ev: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedOption = (options || []).find(
                option => option.id.toString() === ev.currentTarget.value
            );
            onChange(selectedOption);
        },
        [options, onChange]
    );
    const value = selected?.id.toString() || "";

    return (
        <select
            className="custom-select selectFilter"
            value={value}
            disabled={!options}
            onChange={setSelectedOption}
        >
            <option value="" disabled={true}>
                {title}
            </option>

            {options &&
                options.map(option => (
                    <option key={option.id.toString()} value={option.id.toString()}>
                        {option.name}
                    </option>
                ))}
        </select>
    );
}
