import React from "react";
import { Pocket } from "../../domain/entities/Pocket";
import { t } from "../../i18n";

interface PocketInformationProps {
    pocket: Pocket | undefined;
}

export const PocketInformation: React.FC<PocketInformationProps> = React.memo(props => {
    const { pocket } = props;

    const [open, setOpen] = React.useState(true);
    const collapsableBoxStyles = open ? " show" : "";

    return (
        <div id="pocketInformationBox" className="contentBox">
            <div className="contentTitleSmall">
                <h6 className="d-inline-block">
                    <strong>Pocket Info and Bindings</strong>
                    <br />
                </h6>
                <span className="float-right">
                    <a
                        className="collapseToggle"
                        onClick={() => setOpen(!open)}
                        data-toggle="collapse"
                        aria-controls="collapsableBox"
                        aria-expanded="false"
                    >
                        {open ? (
                            <i className="fa fa-chevron-down"></i>
                        ) : (
                            <i className="fa fa-chevron-up"></i>
                        )}
                    </a>
                </span>
            </div>

            <div
                id="collapsableBox"
                className={"contentData collapsable-body collapse" + collapsableBoxStyles}
            >
                <p className="d-inline-block">
                    {pocket ? (
                        <React.Fragment>
                            <b>{pocket.name}</b> - <em>{pocket.description}</em>
                            <br />
                            Binding site score *: {pocket.bindingSiteScore}
                            <br />
                            <br />
                            <br />
                            <em>
                                * Binding site score: propensity of the binding site to bind
                                ligands, unitless; the higher, the better.
                            </em>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>{t("No pocket selected")}</React.Fragment>
                    )}
                </p>
            </div>
        </div>
    );
});
