import React from "react";

export type Direction = "asc" | "desc";
export type Order<Field> = { field: Field; direction: Direction };

export function useOrder<Field>(
    initialOrder: Order<Field>
): [Order<Field>, (field: Field) => () => void] {
    const [order, setOrder] = React.useState<Order<Field>>(initialOrder);

    const toggleOrder = React.useMemo(
        () => (field: Field) => () => {
            const newDirection =
                field === order.field ? (order.direction === "asc" ? "desc" : "asc") : "asc";
            setOrder({ field, direction: newDirection });
        },
        [order, setOrder]
    );

    return [order, toggleOrder];
}
