import { ExperimentRepository, PaginatedResponse } from "./../repositories/ExperimentRepository";
import { Pocket } from "../entities/Pocket";
import { DrugInteraction } from "../entities/DrugInteraction";
import { Options } from "../../data/ExperimentApiRepository";


export class GetDrugInteractions {
    constructor(private experimentRepository: ExperimentRepository) {}

    public async execute(pocket: Pocket, options: Options): Promise<PaginatedResponse<DrugInteraction>> {
        return this.experimentRepository.getDrugInteractions(pocket, options);
    }
}
