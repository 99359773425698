import React from "react";
import { CompositionRoot } from "./CompositionRoot";

export const RootCompositionContext = React.createContext<CompositionRoot | undefined>(undefined);

interface CompositionRootProviderProps {
    apiUrl: string;
}

export const CompositionRootProvider: React.FC<CompositionRootProviderProps> = React.memo(props => {
    const { apiUrl, children } = props;
    const compositionRoot = React.useMemo(() => new CompositionRoot({ apiUrl }), [apiUrl]);

    return (
        <RootCompositionContext.Provider value={compositionRoot}>
            {children}
        </RootCompositionContext.Provider>
    );
});

export function useCompositionRoot(): CompositionRoot {
    const compositionRoot = React.useContext(RootCompositionContext);
    if (!compositionRoot) throw new Error("Composition root not initialized");
    return compositionRoot;
}
