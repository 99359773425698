import { GetExperiments } from "./domain/usecases/GetExperiments";
import { GetPdbs } from "./domain/usecases/GetPdbs";
import { ExperimentRepository } from "./domain/repositories/ExperimentRepository";
import { ExperimentApiRepository } from "./data/ExperimentApiRepository";
import { GetPockets } from "./domain/usecases/GetPockets";
import { GetDrugInteractions } from "./domain/usecases/GetDrugInteractions";

export class CompositionRoot {
    private repositories: {
        experiment: ExperimentRepository;
    };

    constructor(options: { apiUrl: string }) {
        this.repositories = {
            experiment: new ExperimentApiRepository(options.apiUrl),
        };
    }

    public get experiments() {
        const getExperiments = new GetExperiments(this.repositories.experiment);
        const getPdbs = new GetPdbs(this.repositories.experiment);
        const getPockets = new GetPockets(this.repositories.experiment);
        const getDrugInteractions = new GetDrugInteractions(this.repositories.experiment);
        return {
            getExperiments: getExperiments.execute.bind(getExperiments),
            getPdbs: getPdbs.execute.bind(getPdbs),
            getPockets: getPockets.execute.bind(getPockets),
            getDrugInteractions: getDrugInteractions.execute.bind(getDrugInteractions),
        };
    }
}
