import React from "react";
import _ from "lodash";
import { InteractionRow } from "./DrugInteractionRow";
import { Pocket, PocketMethods } from "../../domain/entities/Pocket";
import { t } from "../../i18n";
import { downloadFile } from "../../utils/download";
import { DrugInteraction } from "../../domain/entities/DrugInteraction";
import { useOrder, Order } from "./order";
import { ColumnHeader } from "./ColumnHeader";
import { useCompositionRoot } from "../../CompositionRootContext";
import { PaginatedResponse } from "../../domain/repositories/ExperimentRepository";

interface DrugInteractionsProps {
    pocket: Pocket | undefined;
    perPage?: number;
}

const initialOrder: Order<keyof DrugInteraction> = { field: "dockingScore", direction: "asc" };

export const DrugInteractions: React.FC<DrugInteractionsProps> = React.memo(props => {
    const compositionRoot = useCompositionRoot();
    const { pocket, perPage = 10 } = props;
    const [drugInteractions, setDrugInteractions] = React.useState<
        PaginatedResponse<DrugInteraction>
    >();

    const [page, setPage] = React.useState(1);
    const [filter, setFilter] = React.useState<string>("");

    const [order, toggleOrder] = useOrder<keyof DrugInteraction>(initialOrder);

    const setFilterAndPage = React.useCallback(
        (ev: React.FormEvent<HTMLInputElement>) => {
            setFilter(ev.currentTarget.value);
            setPage(1);
        },
        [setFilter, setPage]
    );

    const submitHandler = React.useCallback((e) => e.preventDefault(),[]);

    const goToNextPage = React.useCallback(() => setPage(page_ => page_ + 1), [setPage]);
    const goToPrevPage = React.useCallback(() => setPage(page_ => page_ - 1), [setPage]);

    const paginator = {
        prevPage: page > 1,
        nextPage: page < calculateMaxPage(drugInteractions?.count, perPage),
    };
    const emptyRows = _.isEmpty(drugInteractions?.results);
    const headerStyle = emptyRows ? styles.hidden : undefined;

    const downloadTable = React.useCallback(async () => {
        if (pocket) {
            const options = {
                perPage: 0,
                page: 1,
                search: filter,
            };
            const drugInteractionsPaginated = await compositionRoot.experiments.getDrugInteractions(
                pocket,
                options
            );
            const file = await new PocketMethods(pocket).getDownload(
                drugInteractionsPaginated.results
            );
            if (file) {
                downloadFile(file);
            }
        }
    }, [compositionRoot, pocket, filter]);

    React.useEffect(() => {
        if (pocket) {
            const options = {
                perPage: perPage,
                page: page,
                search: filter,
                order: order,
            };
            compositionRoot.experiments
                .getDrugInteractions(pocket, options)
                .then(setDrugInteractions);
        } else {
            setDrugInteractions(undefined);
            setFilter("");
        }
    }, [compositionRoot, pocket, page, perPage, filter, order]);

    return (
        <div id="drugInteraction" className="aroundBorder">
            <div className="contentTitle">
                <h4 className="d-inline-flex">
                    <strong>Drug Interaction</strong>
                    <br />
                </h4>

                {pocket && (
                    <React.Fragment>
                        <span className="float-right" style={headerStyle}>
                            <a
                                style={styles.pointer}
                                onClick={paginator.prevPage ? goToPrevPage : undefined}
                            >
                                <i className="fa fa-chevron-left" />
                            </a>
                            {page} of {calculateMaxPage(drugInteractions?.count, perPage)}
                            <a
                                style={styles.pointer}
                                onClick={paginator.nextPage ? goToNextPage : undefined}
                            >
                                <i className="fa fa-chevron-right" />
                            </a>
                        </span>

                        <span className="float-right downloadButton" style={headerStyle}>
                            <button
                                type="button"
                                data-toggle="popover"
                                data-placement="bottom"
                                title={t("Download table: Table data as CSV + PDBS")}
                                onClick={downloadTable}
                                data-html="true"
                                data-content="<h6>Download table</h6><p>Table data as CSV + PDBS</p><a class='card-link' href='#''>Link</a></div>"
                            >
                                <a>
                                    <i className="fa fa-download" />
                                </a>
                            </button>
                        </span>

                        <form id="proteinSearch" className="float-right d-inline-flex" onSubmit={submitHandler}>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Live Search"
                                aria-label="Search"
                                onChange={setFilterAndPage}
                            />
                            <i className="fas fa-search text-grey searchIcon" aria-hidden="true" />
                        </form>
                    </React.Fragment>
                )}
            </div>

            <div className="contentDataTight">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <ColumnHeader
                                    title={t("Compound Name")}
                                    field="compoundId"
                                    order={order}
                                    toggleOrder={toggleOrder}
                                />
                                <ColumnHeader
                                    title={t("Pose Id")}
                                    field="poseId"
                                    order={order}
                                    toggleOrder={toggleOrder}
                                />
                                <ColumnHeader
                                    title={t("Docking Score")}
                                    help={
                                        "Docking score - binding affinity in kcal/mol as computed by Schrodinger glide; the smaller, the better)"
                                    }
                                    field="dockingScore"
                                    order={order}
                                    toggleOrder={toggleOrder}
                                />
                                <ColumnHeader
                                    title={t("Ligand Efficiency")}
                                    help={
                                        "Ligand efficiency - average binding affinity per heavy atom in kcal/mol; the smaller the better)"
                                    }
                                    field="ligandEfficiency"
                                    order={order}
                                    toggleOrder={toggleOrder}
                                />
                                <ColumnHeader title={t("Image")} field="xref" />
                                <th />
                            </tr>
                        </thead>

                        <tbody>
                            {emptyRows && (
                                <tr>
                                    <td style={{ marginTop: 20, marginLeft: 10 }}>
                                        {t("No results")}
                                    </td>
                                </tr>
                            )}

                            {drugInteractions &&
                                drugInteractions.results.map(drugInteraction => (
                                    <InteractionRow
                                        key={drugInteraction.compoundId + "-" + drugInteraction.poseId}
                                        drugInteraction={drugInteraction}
                                    />
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

const styles = {
    hidden: { opacity: 0 },
    pointer: { cursor: "pointer" },
};

function calculateMaxPage(items: number | undefined, perPage: number) {
    return items ? Math.ceil(items / perPage) : 0;
}
