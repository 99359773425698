import { ExperimentRepository } from "./../repositories/ExperimentRepository";
import { Pocket } from "../entities/Pocket";
import { Pdb } from "../entities/Pdb";

export class GetPockets {
    constructor(private experimentRepository: ExperimentRepository) {}

    public async execute(pdb: Pdb): Promise<Pocket[]> {
        return this.experimentRepository.getPockets(pdb);
    }
}
